import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';
import {createClient} from "@supabase/supabase-js";
import {Auth} from "@supabase/auth-ui-react";
import {ThemeSupa} from "@supabase/auth-ui-shared";

const url = "https://zjlvgfuqjlnhwwpnnhvq.supabase.co"
const key = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InpqbHZnZnVxamxuaHd3cG5uaHZxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDU0MTcyNzAsImV4cCI6MjAyMDk5MzI3MH0.5FdjGmvUO1kTm7ay6eEso1WWZotSsm4l9fLtxahqHFs"

const supabase = createClient(url, key)

function App() {
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({data: {session}}) => {
      console.log(session)
      setSession(session as any)
    })

    const {
      data: {subscription},
    } = supabase.auth.onAuthStateChange((_event, session) => {
      console.log(session)
      setSession(session as any)
    })

    return () => subscription.unsubscribe()
  }, [])

  if (!session) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <Auth
            supabaseClient={supabase}
            appearance={{theme: ThemeSupa}}
            providers={[]}
          />
        </div>
      </div>
    );
  } else {
    const currentSession = session as any;
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="bg-white p-8 rounded shadow-md text-center max-w-md w-full mx-auto">
          <div>
            <div className="mb-4">Logged in!</div>
            <div className="mb-4">
              <strong>Email:</strong> {currentSession.user.email}
            </div>
            <div className="mb-4 break-all">
              <strong>Token JWT:</strong> {currentSession.access_token}
            </div>
            <button
              onClick={() => supabase.auth.signOut()}
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
            >
              Sign out
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default App;
